import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useEffect, useState } from 'react'
import Day from './Day'
import styles from './styles.module.css'
import { fetchData } from '../../../../../global/utils/fetch'
import moment from 'moment'
import { IRouteInstruction } from '../../../../../../../app/entities/RouteInstruction'
import {
  IGetOrderDataByIdResponse,
  OrderContext,
} from '../../../../../global/context/order-context/OrderContext'
import {
  AvailabilitySlot,
  DayAvailability,
} from '../../../../../../../app/types/available_calendar'
import { getSchedulingAvailableCalendar } from './api'
import { ICustomPoint } from '../../../../../../../app/entities/lib/CustomPoint'
import SlotColorsLegend from './SlotColorsLegend'

interface IAppointmentSlotsProps {
  setIsForwardDisabled: Function
  useUnvalidatedAddress?: boolean
}

function AppointmentSlots({ setIsForwardDisabled }: IAppointmentSlotsProps) {
  const { orderData, setOrderData, selectedTimes } = useContext(OrderContext)
  const drivingDetails = orderData?.installationPoint?.drivingDetails

  const incomingOrderEndLocation = drivingDetails?.end_location as
    | {
        lat: number
        lng: number
      }
    | undefined
  const incomingOrderCoordinates = {
    x: incomingOrderEndLocation?.lng,
    y: incomingOrderEndLocation?.lat,
  } as ICustomPoint

  const [days, setDays] = useState([])
  const [daysComponents, setDaysComponents] =
    useState<ReactJSXElement[]>(createDays)

  const [isLoading, setLoading] = useState(false)
  const [nextIsLoading] = useState(false)
  const [message, setMessage] = useState<string>(getMessage())

  function getMessage() {
    const drivingDetailsAreAvailable =
      incomingOrderCoordinates &&
      drivingDetails?.distance &&
      drivingDetails?.duration
    if (!drivingDetailsAreAvailable) {
      return 'No details available'
    }

    return `${drivingDetails.distance?.text} - ${drivingDetails.duration?.text} from hub`
  }
  useEffect(() => {
    if (drivingDetails) {
      setMessage(getMessage())
    }
  }, [drivingDetails])

  useEffect(() => {
    const selectedTime = selectedTimes[0]
    if (selectedTime) {
      const isSelectedTimeUnavailable = days.some((day: DayAvailability) => {
        const { date, slots } = day
        const isSameDay = moment.utc(date).isSame(selectedTime, 'day')
        if (isSameDay) {
          return slots.some((slot: AvailabilitySlot) => {
            return (
              moment
                .utc(slot.timeSlot.fromTime)
                .isSame(selectedTime, 'minute') && !slot.available
            )
          })
        }
      })
      setIsForwardDisabled(isSelectedTimeUnavailable)
    }
  }, [selectedTimes, days])

  useEffect(() => {
    async function runFunc() {
      const startOfTodayMoment = moment.utc().startOf('day')
      const fromDate = startOfTodayMoment.clone().valueOf()
      const toDate = startOfTodayMoment.clone().add(1, 'month').valueOf()

      try {
        const locationObjectId = orderData.location?.objectId
        const orderObjectId = orderData.objectId ?? undefined

        const zipCode = orderData.installationPoint?.zipCode ?? ''

        if (locationObjectId) {
          setLoading(true)
          const res = await fetchData<any>(
            getSchedulingAvailableCalendar(
              zipCode,
              locationObjectId,
              fromDate,
              toDate,
              orderObjectId,
              incomingOrderCoordinates,
            ),
          )
          setDays(res)
          setLoading(false)
        } else {
          throw new Error(`A location does not exist for this order!`)
        }
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    runFunc()
  }, [orderData.location])

  function onSetSelected(selectedTimes: number[]) {
    const updatedData = {
      routeInstructions: [
        {
          startTime: moment.utc(selectedTimes[0]).toDate(),
        } as IRouteInstruction,
      ],
    } as IGetOrderDataByIdResponse
    setOrderData(updatedData)
  }
  useEffect(() => {
    setIsForwardDisabled(selectedTimes.length === 0)
  }, [orderData.routeInstructions?.[0]?.startTime])

  function createDays() {
    return days.map((day, idx) => (
      <Day
        setSelected={onSetSelected}
        day={day}
        key={idx}
        isLoading={isLoading}
      />
    ))
  }
  useEffect(() => {
    setDaysComponents(createDays)
  }, [orderData.routeInstructions?.[0]?.startTime, days, isLoading])

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={styles.appointmentSlotsContainer}>
      {orderData.location?.shouldDisplaySchedulingHelpers && (
        <div className={styles.colorLegendsContainer}>
          <SlotColorsLegend />
        </div>
      )}
      <div className={styles.drivingDetailsContainer}>
        <div className={styles.drivingDetailsMessage}>{message}</div>
      </div>
      {nextIsLoading && (
        <div className={styles.overlay}>
          <div className={styles.progressContainer}>
            <CircularProgress size={80} />
          </div>
        </div>
      )}
      <div className={styles.slotsContainer}>
        <div className={styles.daysContainer}>{daysComponents}</div>
      </div>
    </div>
  )
}

export default AppointmentSlots
