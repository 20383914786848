import { useEffect, useState } from 'react'
import { AvailabilitySlot } from '../../../../../../../app/types/available_calendar'
import styles from './styles.module.css'
import moment from 'moment'

interface IExtendedSlotProps extends AvailabilitySlot {
  currentSelected: number[]
  isLoading: boolean
  onClick: Function
}

export function Slot({
  available,
  currentSelected,
  isLoading,
  label,
  onClick,
  timeSlot: { fromTime, toTime },
  style,
}: IExtendedSlotProps) {
  const fromTimeMoment = moment.utc(fromTime)

  const [previouslySelected, setPreviouslySelected] = useState<number[]>()

  // use this to set the previously selected slot
  useEffect(() => {
    if (currentSelected.length) {
      setPreviouslySelected(
        currentSelected.map((dateString) => new Date(dateString).valueOf()),
      )
    }
  }, [])

  let clickHandler = () => onClick()

  let className = styles.slotCard

  if (!available && fromTimeMoment.isSame(currentSelected[0])) {
    clickHandler = () => {}
    className += ` ${styles.unavailable} ${styles.strikeOut}`
  } else if (!available) {
    clickHandler = () => {}
    className += ` ${styles.disabled} ${styles.strikeOut}`
  } else if (previouslySelected?.includes(fromTimeMoment.valueOf())) {
    className += ` ${styles.previous}`
  } else if (isLoading) {
    clickHandler = () => {}
    className += ` ${styles.loading}`
  }

  const isSlotSelected = currentSelected.includes(fromTimeMoment.valueOf())

  if (isSlotSelected) {
    className += ` ${styles.selected}`
  }

  return (
    <div onClick={clickHandler} className={className} style={style?.slot || {}}>
      <div className={styles.slotCardTimeRange} style={style?.label || {}}>
        {label}
      </div>
    </div>
  )
}
