import styles from './slot-colors-legend.module.css'

function SlotColorsLegend() {
  return (
    <div className={styles.slotColorsLegend}>
      <h2 className={styles.slotColorsLegendTitle}>Slot Colors Legend</h2>
      <div className={styles.slotColorsLegendItem}>
        <div
          className={styles.slotColorsLegendItemColor}
          style={{ backgroundColor: '#1ec940' }}
        />
        <div className={styles.slotColorsLegendItemText}>Good Buffer</div>
        <div className={styles.slotColorsLegendItemDescription}>
          Expected on time with 10+ min buffer.
        </div>
      </div>
      <div className={styles.slotColorsLegendItem}>
        <div
          className={styles.slotColorsLegendItemColor}
          style={{ backgroundColor: 'yellow' }}
        />
        <div className={styles.slotColorsLegendItemText}>Tight Window</div>
        <div className={styles.slotColorsLegendItemDescription}>
          May arrive on time, but &lt;10 min buffer before/after.
        </div>
      </div>
      <div className={styles.slotColorsLegendItem}>
        <div
          className={styles.slotColorsLegendItemColor}
          style={{ backgroundColor: '#D70000' }}
        />
        <div className={styles.slotColorsLegendItemText}>High Risk</div>
        <div className={styles.slotColorsLegendItemDescription}>
          Expected miss—risking lateness for this slot or an existing job.
        </div>
      </div>
    </div>
  )
}

export default SlotColorsLegend
