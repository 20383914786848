import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Location } from '../../../../../../../app/entities/Location'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import AppointmentSlots from '../appointment-slot/AppointmentSlots'
import OrderDetails from '../order-details/OrderDetails'
import ConfirmAppointment from '../confirm-appointment/ConfirmAppointment'
import CustomerInformation from '../customer-information/CustomerInformation'
import TireSizeQuantity from '../size-quantity/TireSizeQuantity'
import styles from './styles.module.css'
import {
  SCHEDULER_MODES,
  SCHEDULER_STEPS,
} from '../../../../../global/constants/scheduler'
import { fetchData, IFetchDataProps } from '../../../../../global/utils/fetch'
import {
  getLocationById,
  postOrder,
  putAppointmentTime,
  putCustomer,
  putCustomerVehicle,
  putDisposalCount,
  putInstallationPoint,
  putOrder,
  putOrderIsRework,
  putOrderStatus,
  putTiresCombination,
} from '../../../api'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'
import { Order } from '../../../../../../../app/entities/Order'
import { overwriteOrderNote } from '../../../../../work-orders/components/api'
import { STATUSES } from '../../../../../global/constants/order-status'
import EditAddress from '../edit-address/EditAddress'
import {
  IGetOrderDataByIdResponse,
  OrderContext,
} from '../../../../../global/context/order-context/OrderContext'
import { InstallationPoint } from '../../../../../../../app/entities/InstallationPoint'
import createInstallationPointFromGeocode from '../../../../../global/utils/installation-point/create/from-geocode'
import { OrderService } from '../../../../../../../app/entities/OrderService'
import { SERVICE_NAMES } from '../../../../../global/constants/service'

interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
  mode: string
  initialUseUnvalidatedAddress?: boolean

  // Props for existing order thru the edit/finish flows
  orderData?: IGetOrderDataByIdResponse
  chosenStep?: string

  // Props for new order thru the new apt flow
  chosenAddress?: google.maps.GeocoderResult | null
  chosenLocationId?: string
  setChosenAddress?: Dispatch<SetStateAction<google.maps.GeocoderResult | null>>
}

const { TIRE_DISPOSAL, REWORK } = SERVICE_NAMES

const { EDIT, FINISH, SCHEDULER } = SCHEDULER_MODES
const MODE_NOT_SUPPORTED_MESSAGE = 'Mode not supported, please contact support!'

const {
  APPOINTMENT_SLOTS,
  EDIT_ADDRESS,
  CUSTOMER_INFO,
  TIRE_SIZE_AND_QUANTITY,
  ORDER_DETAILS,
  CONFIRM_APPOINTMENT,
} = SCHEDULER_STEPS

const SCHEDULER_MODE_STEPS = [
  APPOINTMENT_SLOTS,
  CUSTOMER_INFO,
  TIRE_SIZE_AND_QUANTITY,
  ORDER_DETAILS,
  CONFIRM_APPOINTMENT,
]

const FINISH_MODE_STEPS = [
  EDIT_ADDRESS,
  APPOINTMENT_SLOTS,
  CUSTOMER_INFO,
  TIRE_SIZE_AND_QUANTITY,
  ORDER_DETAILS,
  CONFIRM_APPOINTMENT,
]

const FORWARD_BUTTON_LABELS = {
  CONFIRM: 'Confirm',
  DONE: 'Done',
  NEXT: 'Next',
  SAVE: 'Save',
}

const DEFAULT_STATE_VALUES = {
  DEFAULT_STEP_NAMES: {
    [`${FINISH}`]: EDIT_ADDRESS,
    [`${SCHEDULER}`]: APPOINTMENT_SLOTS,
  } as Record<string, string>,
  IS_BACK_DISABLED: true,
  IS_FORWARD_DISABLED: true,
  FORWARD_BUTTON_LABEL: FORWARD_BUTTON_LABELS.NEXT,
  DISPOSAL_COUNT: 0,
  IS_LOADING: false,
  IS_REWORK: false,
  IS_SCHEDULED: false,
}

export default function ScheduleModal({
  // required
  isOpen,
  handleClosed,
  mode,
  initialUseUnvalidatedAddress,

  // optional (used for edit mode)
  chosenStep,

  // optional (used for other modes)
  chosenAddress,
  chosenLocationId,
  setChosenAddress,
}: IScheduleModalProps) {
  const { setToastStatus } = useContext(ToastNotificationContext)
  const {
    clearOrderData,
    queryOrderData,
    resetOrderData,
    setOrderData,
    setInstallationPoint,
    customerVehicleData: customerVehicle,
    orderData,
    selectedTimes,
    tiresCombinationData: tiresCombination,
  } = useContext(OrderContext)
  const { customer, installationPoint, location } = orderData

  const [isZipCodeServiced, setIsZipCodeServiced] = useState<any>(undefined)
  const [useUnvalidatedAddress, setUseUnvalidatedAddress] = useState<boolean>(
    initialUseUnvalidatedAddress ?? false,
  )
  const [stepName, setStepName] = useState(getStepName)
  const [currentSteps, setCurrentSteps] = useState<string[]>(getSteps)
  const [isBackDisabled, setIsBackDisabled] = useState(
    DEFAULT_STATE_VALUES.IS_BACK_DISABLED,
  )
  const [isForwardDisabled, setIsForwardDisabled] = useState(
    DEFAULT_STATE_VALUES.IS_FORWARD_DISABLED,
  )
  const [forwardButtonLabel, setForwardButtonLabel] = useState(
    getForwardButtonLabel,
  )
  const [disposalCount, setDisposalCount] = useState(getDisposalCount())
  const [isRework, setIsRework] = useState(getReworkStatus())
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE_VALUES.IS_LOADING)
  const [isScheduled, setIsScheduled] = useState(
    DEFAULT_STATE_VALUES.IS_SCHEDULED,
  )

  function getReworkStatus() {
    return orderData.orderServices.some(
      (orderService: OrderService) => orderService.label === REWORK,
    )
  }

  function getDisposalCount() {
    return (
      orderData.orderServices.find(
        (orderService: OrderService) => orderService.label === TIRE_DISPOSAL,
      )?.quantity ?? 0
    )
  }

  function getSteps() {
    return mode === FINISH ? FINISH_MODE_STEPS : SCHEDULER_MODE_STEPS
  }
  function getForwardButtonLabel() {
    return mode === EDIT
      ? FORWARD_BUTTON_LABELS.SAVE
      : DEFAULT_STATE_VALUES.FORWARD_BUTTON_LABEL
  }
  // Ensure currentSteps is properly set on mount
  useEffect(() => {
    setCurrentSteps(getSteps())
    setForwardButtonLabel(getForwardButtonLabel())
  }, [mode])
  // Ensure currentSteps is properly set on mount
  useEffect(() => {
    setCurrentSteps(getSteps())
  }, [mode])

  function getStepName() {
    return Boolean(chosenStep)
      ? (chosenStep as string)
      : DEFAULT_STATE_VALUES.DEFAULT_STEP_NAMES[mode]
  }
  // Ensure stepName is properly set on mount and when chosenStep changes
  useEffect(() => {
    setStepName(getStepName())
  }, [chosenStep, mode])

  async function maybeUpdateOrderDataLocation() {
    const shouldUpdateOrderDataLocation =
      Boolean(chosenLocationId) &&
      chosenLocationId !== orderData.location?.objectId
    if (shouldUpdateOrderDataLocation) {
      const location = await fetchData<Location>(
        getLocationById(chosenLocationId as string),
      )
      setOrderData({ location })
    }
  }
  // Set orderData.Location for SCHEDULER mode
  useEffect(() => {
    maybeUpdateOrderDataLocation()
  }, [chosenLocationId, orderData.location])

  // Determine if the forward button should be disabled. This useEffect
  // could potentially compute this boolean for all steps, but it's tied
  // to state that's instantiated in multiple child components and it's
  // easier to manage this way since we're using OrderContext
  useEffect(() => {
    const shouldDisableForward =
      mode === FINISH && stepName === EDIT_ADDRESS && !isZipCodeServiced
    setIsForwardDisabled(shouldDisableForward)
  }, [isZipCodeServiced])

  // Move to the next page if the user is cloning an order and
  // wants to use an unvalidated address
  useEffect(() => {
    const shouldMoveToNextPage =
      mode === FINISH && stepName === EDIT_ADDRESS && useUnvalidatedAddress
    if (shouldMoveToNextPage) {
      forwardClick()
    }
  }, [useUnvalidatedAddress])

  const [renderedComponent, setRenderedComponent] = useState<React.ReactNode>()
  // The EditAddress modal is optionally shown in the CustomerInformation
  // component, so we need to manage its state here
  function renderComponent() {
    if (stepName === APPOINTMENT_SLOTS) {
      return (
        <AppointmentSlots
          setIsForwardDisabled={setIsForwardDisabled}
          useUnvalidatedAddress={useUnvalidatedAddress}
        />
      )
    } else if (stepName === CUSTOMER_INFO) {
      return (
        <CustomerInformation
          chosenAddress={chosenAddress}
          mode={mode}
          setChosenAddress={
            setChosenAddress as Dispatch<
              SetStateAction<google.maps.GeocoderResult | null>
            >
          }
          setIsForwardDisabled={setIsForwardDisabled}
        />
      )
    } else if (stepName === EDIT_ADDRESS) {
      return (
        <EditAddress
          chosenAddress={chosenAddress}
          isZipCodeServiced={isZipCodeServiced}
          handleNextClicked={handleEditAddressNextClicked}
          setChosenAddress={setChosenAddress as any}
          setIsZipCodeServiced={setIsZipCodeServiced}
          setUseUnvalidatedAddress={setUseUnvalidatedAddress}
        />
      )
    } else if (stepName === TIRE_SIZE_AND_QUANTITY) {
      return (
        <TireSizeQuantity
          disposalCount={disposalCount}
          setIsForwardDisabled={setIsForwardDisabled}
          setDisposalCount={setDisposalCount}
        />
      )
    } else if (stepName === ORDER_DETAILS) {
      return (
        <OrderDetails
          chosenAddress={chosenAddress}
          isRework={isRework}
          mode={mode}
          setIsRework={setIsRework}
          setIsForwardDisabled={setIsForwardDisabled}
        />
      )
    } else if (stepName === CONFIRM_APPOINTMENT) {
      return (
        <ConfirmAppointment
          disposalCount={disposalCount}
          isRework={isRework}
          isScheduled={isScheduled}
        />
      )
    }
  }
  useEffect(() => {
    setRenderedComponent(renderComponent)
  }, [
    chosenAddress,
    customer,
    customerVehicle,
    disposalCount,
    installationPoint,
    isOpen,
    isRework,
    isScheduled,
    mode,
    orderData,
    orderData.installationPoint,
    orderData.orderVehicles[0].tiresCombination,
    selectedTimes,
    stepName,
    tiresCombination,
  ])

  function incrementStepIndex() {
    setStepName(currentSteps[currentSteps.indexOf(stepName) + 1])
  }
  function decrementStepIndex() {
    setStepName(currentSteps[currentSteps.indexOf(stepName) - 1])
  }
  async function forwardClick() {
    const isSchedulerMode = mode === SCHEDULER
    const isEditMode = mode === EDIT
    const isFinishMode = mode === FINISH
    try {
      let saveCalls: IFetchDataProps<any>[] = []

      if (isSchedulerMode) {
        if (stepName === APPOINTMENT_SLOTS) {
          incrementStepIndex()
        } else if (stepName === CUSTOMER_INFO) {
          incrementStepIndex()
        } else if (stepName === TIRE_SIZE_AND_QUANTITY) {
          incrementStepIndex()
        } else if (stepName === ORDER_DETAILS) {
          incrementStepIndex()
          setForwardButtonLabel(FORWARD_BUTTON_LABELS.CONFIRM)
        } else if (
          stepName === CONFIRM_APPOINTMENT &&
          forwardButtonLabel === FORWARD_BUTTON_LABELS.CONFIRM
        ) {
          const order = await confirmOrder()
          if (order) {
            setForwardButtonLabel(FORWARD_BUTTON_LABELS.DONE)
          }
        } else if (
          stepName === CONFIRM_APPOINTMENT &&
          forwardButtonLabel === FORWARD_BUTTON_LABELS.DONE
        ) {
          handleClosed()
        }
      } else if (isEditMode) {
        if (stepName === APPOINTMENT_SLOTS) {
          saveCalls.push(putAppointmentTime(orderData.objectId, selectedTimes))
        } else if (stepName === CUSTOMER_INFO) {
          saveCalls.push(putInstallationPoint(orderData.installationPoint))
          saveCalls.push(
            overwriteOrderNote(orderData.objectId, orderData.note || ''),
          )
          saveCalls.push(putCustomer(orderData.customer))
          saveCalls.push(putCustomerVehicle(customerVehicle))
        } else if (stepName === TIRE_SIZE_AND_QUANTITY) {
          saveCalls.push(putDisposalCount(orderData.objectId, disposalCount))
          saveCalls.push(putTiresCombination(tiresCombination))
        } else if (stepName === ORDER_DETAILS) {
          saveCalls.push(
            putOrder({
              objectId: orderData.objectId,
              salesRepresentativeNumber: orderData.salesRepresentativeNumber,
              tireSource: orderData.tireSource,
              orderSource: orderData.orderSource,
              tireLocation: orderData.tireLocation,
            } as Order),
          )
        }

        const wereSaveCallsFound = saveCalls.length > 0
        if (!wereSaveCallsFound) {
          throw new Error(
            `Update function found for step "${stepName}". Please contact support!`,
          )
        }
      } else if (isFinishMode) {
        if (stepName === EDIT_ADDRESS) {
          if (chosenAddress) {
            const installationPointValuesFromGoogle =
              await createInstallationPointFromGeocode(
                chosenAddress,
                location?.objectId ?? '',
              )

            const newInstallationPoint = {
              ...installationPoint,
              ...installationPointValuesFromGoogle,
            } as unknown as InstallationPoint

            setOrderData({ installationPoint: newInstallationPoint })

            saveCalls.push(putInstallationPoint(newInstallationPoint))
          } else {
            saveCalls.push(putInstallationPoint(installationPoint))
          }
          incrementStepIndex()
        } else if (stepName === APPOINTMENT_SLOTS) {
          saveCalls.push(putAppointmentTime(orderData.objectId, selectedTimes))
          incrementStepIndex()
        } else if (stepName === CUSTOMER_INFO) {
          saveCalls.push(putInstallationPoint(orderData.installationPoint))
          saveCalls.push(
            overwriteOrderNote(orderData.objectId, orderData.note || ''),
          )
          saveCalls.push(putCustomer(orderData.customer))
          saveCalls.push(putCustomerVehicle(customerVehicle))
          incrementStepIndex()
        } else if (stepName === TIRE_SIZE_AND_QUANTITY) {
          saveCalls.push(putDisposalCount(orderData.objectId, disposalCount))
          saveCalls.push(putTiresCombination(tiresCombination))
          incrementStepIndex()
        } else if (stepName === ORDER_DETAILS) {
          setForwardButtonLabel(FORWARD_BUTTON_LABELS.CONFIRM)

          saveCalls.push(
            putOrder({
              note: orderData.note,
              objectId: orderData.objectId,
              orderSource: orderData.orderSource,
              salesRepresentativeNumber: orderData.salesRepresentativeNumber,
              tireLocation: orderData.tireLocation,
              tireSource: orderData.tireSource,
            } as Order),
          )
          saveCalls.push(putOrderIsRework(orderData.objectId, isRework))
          incrementStepIndex()
        } else if (
          stepName === CONFIRM_APPOINTMENT &&
          forwardButtonLabel === FORWARD_BUTTON_LABELS.CONFIRM
        ) {
          const order = await fetchData<Order>(
            putOrderStatus(orderData.objectId, STATUSES.scheduled),
          )

          const orderWasScheduled = order.status === STATUSES.scheduled
          if (orderWasScheduled) {
            setIsScheduled(true)
            setForwardButtonLabel(FORWARD_BUTTON_LABELS.DONE)
          }
        } else if (
          stepName === CONFIRM_APPOINTMENT &&
          forwardButtonLabel === FORWARD_BUTTON_LABELS.DONE
        ) {
          handleClosed()
        }
      } else {
        console.error(MODE_NOT_SUPPORTED_MESSAGE)
        setToastStatus(() => ({
          isOpen: true,
          message: MODE_NOT_SUPPORTED_MESSAGE,
          severity: 'error',
        }))
      }

      for (const saveCall of saveCalls) {
        await fetchData<any>(saveCall)
      }

      const shouldCloseThisModal =
        mode === EDIT ||
        (mode === FINISH &&
          stepName === CONFIRM_APPOINTMENT &&
          forwardButtonLabel === FORWARD_BUTTON_LABELS.DONE)
      if (shouldCloseThisModal) {
        handleClosed()
        queryOrderData(orderData.objectId)
      }
    } catch (err: any) {
      console.error(err)

      setToastStatus(() => ({
        isOpen: true,
        message: err.message,
        severity: 'error',
      }))
    }
  }

  function backClick() {
    setForwardButtonLabel(FORWARD_BUTTON_LABELS.NEXT)
    decrementStepIndex()
  }

  async function handleEditAddressNextClicked() {
    if (chosenAddress) {
      const installationPointValuesFromGoogle =
        await createInstallationPointFromGeocode(
          chosenAddress,
          location?.objectId || '',
        )
      setInstallationPoint(
        installationPointValuesFromGoogle as InstallationPoint,
      )
    }
  }

  async function confirmOrder(): Promise<Order | undefined> {
    let order: Order | undefined
    if (isScheduled) {
      handleClosed()
    } else {
      setIsLoading(true)
      try {
        order = await fetchData(postOrder(orderData, disposalCount, isRework))
        setForwardButtonLabel(FORWARD_BUTTON_LABELS.DONE)
        setIsScheduled(true)
      } catch (err) {
        console.error(err)
        alert('Something went wrong, please try again later.')

        return undefined
      } finally {
        setIsLoading(false)

        return order
      }
    }
  }

  function resetState() {
    clearOrderData()

    setDisposalCount(DEFAULT_STATE_VALUES.DISPOSAL_COUNT)
    setForwardButtonLabel(DEFAULT_STATE_VALUES.FORWARD_BUTTON_LABEL)
    setIsRework(DEFAULT_STATE_VALUES.IS_REWORK)
    setIsScheduled(DEFAULT_STATE_VALUES.IS_SCHEDULED)
    setIsForwardDisabled(DEFAULT_STATE_VALUES.IS_FORWARD_DISABLED)
    setStepName(getStepName())
  }
  useEffect(() => {
    const shouldResetOrderState = mode === SCHEDULER && !isOpen
    if (shouldResetOrderState) {
      // Clear modal state when when the modal is closed
      resetState()
    }
  }, [isOpen])

  useEffect(() => {
    const shouldDisableBack =
      ([FINISH, SCHEDULER] as string[]).includes(mode ?? '') &&
      currentSteps.indexOf(stepName) === 0

    setIsBackDisabled(shouldDisableBack)
  }, [stepName])

  function handleNavClick(step: string) {
    resetOrderData()
    setStepName(step)
  }

  const NAV_LINKS = [
    CUSTOMER_INFO,
    TIRE_SIZE_AND_QUANTITY,
    APPOINTMENT_SLOTS,
    ORDER_DETAILS,
  ]
  const NAV_LINK_ELEMENTS = NAV_LINKS.map((link, idx) => (
    <button
      className={`${styles.navLink} ${
        stepName === link ? styles.active : null
      }`}
      onClick={() => handleNavClick(link)}
      key={idx}
    >
      {link}
    </button>
  ))

  async function handleCancel() {
    const shouldUpdateOrderToUnconfirmed = mode === FINISH
    if (shouldUpdateOrderToUnconfirmed) {
      await fetchData<Order>(
        putOrderStatus(orderData.objectId, STATUSES.unconfirmed),
      )
    }

    const needToDisplayWorkOrderInfo = mode !== SCHEDULER
    if (needToDisplayWorkOrderInfo) {
      queryOrderData(orderData.objectId)
    }
    handleClosed()
  }

  const LEFT_NAV_BUTTONS =
    mode === SCHEDULER_MODES.EDIT ? (
      <div className={styles.pagesLinksContainer}>
        <div className={styles.pagesLinks}>{NAV_LINK_ELEMENTS}</div>
        <i className={styles.navigationMessage}>
          *Navigating pages will delete any unsaved changes
        </i>
      </div>
    ) : (
      <>
        {[FORWARD_BUTTON_LABELS.NEXT, FORWARD_BUTTON_LABELS.CONFIRM].includes(
          forwardButtonLabel,
        ) && (
          <>
            <SecondaryButton
              disabled={isBackDisabled}
              buttonName='Back'
              onClick={backClick}
            />
            <PrimaryButton buttonName='Cancel' onClick={handleCancel} />
          </>
        )}
      </>
    )

  const RIGHT_NAV_BUTTONS = (
    <>
      {mode === SCHEDULER_MODES.EDIT && (
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => {
            queryOrderData(orderData.objectId)
            handleClosed()
          }}
        />
      )}
      <PrimaryButton
        disabled={isForwardDisabled}
        buttonName={forwardButtonLabel}
        onClick={forwardClick}
      />
    </>
  )

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.scheduleModal}>
          {isLoading && (
            <div className={styles.overlay}>
              <div className={styles.progressContainer}>
                <CircularProgress size={80} />
              </div>
            </div>
          )}
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            {stepName}
          </div>
          <div
            style={{ maxHeight: '100%', width: '100%', overflowX: 'scroll' }}
          >
            {renderedComponent}
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.flexGroupButtons}>{LEFT_NAV_BUTTONS}</div>
            <div className={styles.flexGroupButtons}>{RIGHT_NAV_BUTTONS}</div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
