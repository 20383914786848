import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useEffect } from 'react'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { getLocationById, getAverageJobTimes } from '../../api'
import { LocationInfo } from '../location-info/LocationInfo'
import styles from './styles.module.css'
import { ILocation } from '../../../../../../../app/entities/Location'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import { IWeekCalendar } from '../../../../../../../app/entities/WeekCalendar'
import { WeekCalendar } from '../week-calender/WeekCalendar'
import ZipCodesManager from '../zip-codes-manager/ZipCodesManager'
import { IDaySetting } from '../../../../../../../app/entities/DaySetting'
import DaySettingsManager from '../day-settings-manager/DaySettingsManager'
import TechnicianEmailManager from '../technician-emails-manager/TechnicianEmailManager'
import TireLocationsManager from '../tire-locations-manager/TireLocationsManager'
import SpokeLocationManager from '../spoke-location-manager/SpokeLocationsManager'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import determineIfRightColumnIsVisible from '../../helper-functions/determine-if-right-column-is-visible'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import userHasPermission from '../../../../../global/utils/user/has-permission'
import AlertsManager from '../alerts-manager/AlertsManager'
import { DEFAULT_WEEK_CALENDAR } from './LocationModal'

const { CATEGORY } = PERMISSIONS.LOCATION_SETTINGS

const {
  WORKDAY_INFO,
  VIEW_SERVICED_ZIP_CODES,
  TECHNICIAN_EMAILS,
  SPOKE_LOCATIONS,
  TIRE_LOCATIONS,
  BLOCKED_OFF_TIMES,
  NEW_ORDER_ALERTS,
} = PERMISSIONS.LOCATION_SETTINGS.ENTRIES

interface ILocationEditProps {
  isNew: boolean
  locationObjectId?: string
  location: Partial<ILocation>
  setLocation: Function
  tabulationAreas: Partial<ITabulationArea>[]
  setTabulationAreas: Function
  weekCalendar: Partial<IWeekCalendar>
  setWeekCalendar: Function
  daySettings: Partial<IDaySetting>[]
  setDaySettings: Function
}

export default function LocationEdit({
  isNew,
  locationObjectId,
  location,
  setLocation,
  tabulationAreas,
  setTabulationAreas,
  weekCalendar,
  setWeekCalendar,
  daySettings,
  setDaySettings,
}: ILocationEditProps) {
  const { user } = useContext(UserContext)
  const { data: locationData, isLoading: isLocationLoading } =
    useFetch<ILocation>(getLocationById(locationObjectId || ''))

  useEffect(() => {
    if (isNew) return

    if (locationData) {
      setLocation({
        ...location,
        ...locationData,
      })

      setTabulationAreas(
        locationData.tabulationAreas || ([] as ITabulationArea[]),
      )

      const weekCalendarData = locationData.weekCalendars?.[0] || {
        ...DEFAULT_WEEK_CALENDAR,
      }

      setWeekCalendar(weekCalendarData)

      const daySettings = locationData.daySettings || []

      setDaySettings(daySettings)
    }
  }, [locationData])

  const { data: averageJobTimes, isLoading: isAverageJobTimesLoading } =
    locationObjectId
      ? useFetch<{
          averageDriveTime: number
          averageInstallTime: number
        }>(getAverageJobTimes(locationObjectId || ''))
      : { data: null, isLoading: false }

  if (isLocationLoading || isAverageJobTimesLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  const isRightColumnVisible = user && determineIfRightColumnIsVisible(user)

  return (
    <div className={styles.locationEditComponentsHolder}>
      <LocationInfo
        location={location}
        setLocation={setLocation}
        isNew={isNew}
        averageJobTimes={averageJobTimes}
      />
      {isRightColumnVisible && (
        <div className={styles.locationAvailabilityDetailsHolder}>
          {userHasPermission(user).view(CATEGORY, WORKDAY_INFO.NAME) && (
            <WeekCalendar
              weekCalendar={weekCalendar}
              setWeekCalendar={setWeekCalendar}
              isReadOnly={
                !userHasPermission(user).edit(CATEGORY, WORKDAY_INFO.NAME)
              }
            />
          )}
          {!isNew && (
            <>
              {userHasPermission(user).allow(
                CATEGORY,
                VIEW_SERVICED_ZIP_CODES.NAME,
              ) && (
                <ZipCodesManager
                  tabulationAreas={tabulationAreas}
                  setTabulationAreas={setTabulationAreas}
                />
              )}
              {userHasPermission(user).view(
                CATEGORY,
                TECHNICIAN_EMAILS.NAME,
              ) && (
                <TechnicianEmailManager
                  location={location}
                  setLocation={setLocation}
                  isReadOnly={
                    !userHasPermission(user).edit(
                      CATEGORY,
                      TECHNICIAN_EMAILS.NAME,
                    )
                  }
                />
              )}
              {userHasPermission(user).view(
                CATEGORY,
                NEW_ORDER_ALERTS.NAME,
              ) && (
                <AlertsManager
                  location={location}
                  setLocation={setLocation}
                  isReadOnly={
                    !userHasPermission(user).edit(
                      CATEGORY,
                      NEW_ORDER_ALERTS.NAME,
                    )
                  }
                />
              )}
              {userHasPermission(user).view(CATEGORY, SPOKE_LOCATIONS.NAME) && (
                <SpokeLocationManager
                  location={location}
                  setLocation={setLocation}
                  isReadOnly={
                    !userHasPermission(user).edit(
                      CATEGORY,
                      SPOKE_LOCATIONS.NAME,
                    )
                  }
                />
              )}
              {userHasPermission(user).view(CATEGORY, TIRE_LOCATIONS.NAME) && (
                <TireLocationsManager
                  location={location}
                  setLocation={setLocation}
                  isReadOnly={
                    !userHasPermission(user).edit(CATEGORY, TIRE_LOCATIONS.NAME)
                  }
                />
              )}
              {userHasPermission(user).view(
                CATEGORY,
                BLOCKED_OFF_TIMES.NAME,
              ) && (
                <DaySettingsManager
                  daySettings={daySettings}
                  setDaySettings={setDaySettings}
                  isReadOnly={
                    !userHasPermission(user).edit(
                      CATEGORY,
                      BLOCKED_OFF_TIMES.NAME,
                    )
                  }
                  location={location}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
