import { ICustomPoint } from '../../../../../../../app/entities/lib/CustomPoint'

export function getSchedulingAvailableCalendar(
  zipCode: string,
  locationObjectId: string,
  fromDate: number,
  toDate: number,
  orderObjectId: string,
  incomingOrderCoordinates: ICustomPoint,
) {
  return {
    method: 'POST',
    body: {
      zipCode,
      locationObjectId,
      orderObjectId,
      fromDate,
      toDate,
      incomingOrderCoordinates,
    },
    url: `${process.env.REACT_APP_ORIGIN}/scheduling/available-calendar`,
  }
}
